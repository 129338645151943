import type {Reducer} from 'react';
import {useCallback, useEffect, useMemo, useReducer, useRef} from 'react';

type Runner<Args extends unknown[], Result> = (signal : AbortSignal, ...args : Args) => Promise<Result>;

type InternalAsyncState<Result> = {
    loading : true;
    error : null;
    result : null;
} | {
    loading : false;
    error : Error;
    result : null;
} | {
    loading : false;
    error : null;
    result : Result;
};

export type AsyncState<Result, AsyncAction = unknown> = InternalAsyncState<Result> & {
    reload : () => void;
    replace : (result : Result) => void;
    dispatch : (action : AsyncAction) => void;
};

type LoadStartAction = {
    type : 'loadStart';
};

type LoadErrorAction = {
    type : 'loadError';
    payload : {
        error : Error;
    };
};

type LoadSuccessAction<Result> = {
    type : 'loadSuccess';
    payload : {
        result : Result;
    };
};

type AsyncReducer<Result, AsyncAction> = (state : Result, action : AsyncAction) => Result;

type AsyncDispatchAction<Result, AsyncAction> = {
    type : 'asyncDispatch';
    payload : {
        reducer : AsyncReducer<Result, AsyncAction>;
        action : AsyncAction;
    };
};

type Action<Result, AsyncAction> =
    LoadStartAction
    | LoadErrorAction
    | LoadSuccessAction<Result>
    | AsyncDispatchAction<Result, AsyncAction>;

const internalReducer = <Result, AsyncAction>(
    state : InternalAsyncState<Result>,
    action : Action<Result, AsyncAction>
) : InternalAsyncState<Result> => {
    switch (action.type) {
        case 'loadStart':
            return {
                loading: true,
                error: null,
                result: null,
            };

        case 'loadError':
            return {
                loading: false,
                error: action.payload.error,
                result: null,
            };

        case 'loadSuccess':
            return {
                loading: false,
                error: null,
                result: action.payload.result,
            };

        case 'asyncDispatch':
            if (!state.result) {
                return state;
            }

            return {
                loading: false,
                error: null,
                result: action.payload.reducer(state.result, action.payload.action),
            };
    }
};

const useAsync = <Args extends unknown[], Result, AsyncAction>(
    runner : Runner<Args, Result>,
    args : Args,
    reducer ?: AsyncReducer<Result, AsyncAction>,
    initial ?: Result
) : AsyncState<Result, AsyncAction> => {
    const [state, dispatch] = useReducer<Reducer<InternalAsyncState<Result>, Action<Result, AsyncAction>>>(
        internalReducer,
        initial ? {loading: false, error: null, result: initial} : {loading: true, error: null, result: null}
    );
    const initialLoaded = useRef(false);
    const mounted = useRef(false);
    const abortController = useRef<AbortController>();

    const loadData = useCallback(() => {
        abortController.current?.abort();
        const controller = new AbortController();
        abortController.current = controller;

        dispatch({type: 'loadStart'});

        (async () => {
            const result = await runner(controller.signal, ...args);

            if (mounted.current) {
                dispatch({type: 'loadSuccess', payload: {result}});
            }
        })().catch(error => {
            if (error instanceof Error && error.name === 'AbortError') {
                return;
            }

            dispatch({
                type: 'loadError',
                payload: {error: error instanceof Error ? error : new Error('Unrecognized error')},
            });
        });
    }, [runner, ...args]);

    useEffect(() => {
        mounted.current = true;

        if (!initial || initialLoaded.current) {
            loadData();
        }

        initialLoaded.current = true;

        return () => {
            mounted.current = false;
            abortController.current?.abort();
        };
    }, [loadData, initial]);

    return useMemo(() => {
        return {
            ...state,
            reload: loadData,
            replace: (result : Result) => {
                dispatch({type: 'loadSuccess', payload: {result}});
            },
            dispatch: (action : AsyncAction) => {
                if (!reducer) {
                    throw new Error('No reducer defined');
                }

                dispatch({
                    type: 'asyncDispatch',
                    payload: {reducer, action},
                });
            },
        };
    }, [state, loadData, reducer]);
};

export default useAsync;
