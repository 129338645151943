import {Spinner, Stack} from 'react-bootstrap';

type Props = {
    message ?: string;
};

const FullPageLoadingIndicator = ({message} : Props) : JSX.Element => {
    return (
        <Stack className="align-items-center justify-content-center vh-100">
            <Spinner animation="border"/>
            {message}
        </Stack>
    );
};

export default FullPageLoadingIndicator;
