import type {ReactNode} from 'react';
import {useEffect, useRef, useState} from 'react';
import {useAuth} from 'react-oidc-context';
import AuthError from '@/components/Auth/AuthError';
import FullPageLoadingIndicator from '@/components/FullPageLoadingIndicator';

type Props = {
    children ?: ReactNode | undefined;
};

const AuthGuard = ({children} : Props) : JSX.Element => {
    const auth = useAuth();
    const [redirectError, setRedirectError] = useState(false);
    const performingRedirect = useRef(false);

    const performSigninRedirect = () => {
        if (performingRedirect.current) {
            return;
        }

        setRedirectError(false);
        performingRedirect.current = true;
        auth.signinSilent().catch(e => {
            console.error('login error', e);
            localStorage.clear();
            auth.signinRedirect({
                state: {returnTo: `${window.location.pathname}${window.location.search}`},
            }).catch(() => {
                setRedirectError(true);
            }).finally(() => {
                performingRedirect.current = false;
            });
        }).finally(() => {
            performingRedirect.current = false;
        });
    };

    useEffect(() => {
        if (auth.isLoading || auth.error || auth.isAuthenticated || auth.activeNavigator) {
            return;
        }

        setTimeout(performSigninRedirect, 1000);
    }, [auth]);

    if (redirectError) {
        return <AuthError message="Unable to reach authentication server." onRetry={performSigninRedirect}/>;
    }

    if (auth.activeNavigator === 'signinSilent' || auth.activeNavigator === 'signinRedirect') {
        return <FullPageLoadingIndicator message="Loading…"/>;
    }

    if (auth.activeNavigator === 'signoutRedirect') {
        return <FullPageLoadingIndicator message="Signing you out…"/>;
    }

    if (auth.isLoading) {
        return <FullPageLoadingIndicator message="Loading…"/>;
    }

    if (auth.error) {
        return <AuthError message={auth.error.message} onRetry={performSigninRedirect}/>;
    }

    if (!auth.isAuthenticated) {
        return <FullPageLoadingIndicator message="Loading…"/>;
    }

    return <>{children}</>;
};

export default AuthGuard;
