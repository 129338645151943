import {Alert, Button} from 'react-bootstrap';

type Props = {
    heading ?: string;
    message : string;
    onRetry : () => void;
};

const RetryableError = ({heading, message, onRetry} : Props) : JSX.Element => (
    <Alert variant="danger">
        <Alert.Heading>{heading ?? 'Error'}</Alert.Heading>
        <p>{message}</p>
        <hr/>
        <div className="d-flex justify-content-end">
            <Button onClick={onRetry}>Retry</Button>
        </div>
    </Alert>
);

export default RetryableError;
