import {Container} from 'react-bootstrap';
import RetryableError from '@/components/RetryableError';

type Props = {
    message : string;
    onRetry : () => void;
};

const AuthError = ({message, onRetry} : Props) : JSX.Element => (
    <div className="d-flex align-items-center justify-content-center vh-100">
        <Container>
            <RetryableError heading="Authentication failed" message={message} onRetry={onRetry}/>
        </Container>
    </div>
);

export default AuthError;
