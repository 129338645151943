import {useCallback, useEffect, useRef} from 'react';
import {useAuth} from 'react-oidc-context';

export const apiUrl = (path : string) : URL => new URL(path, import.meta.env.VITE_APP_API_BASE_URL);

export type ApiFetch = typeof fetch;

const useApiFetch = () : ApiFetch => {
    const auth = useAuth();
    const accessToken = useRef(auth.user?.access_token);

    useEffect(() => {
        // We have to utilize a ref for the access token, otherwise everytime the user object is updated, all components
        // relying on `useApiFetch()` would re-render.
        accessToken.current = auth.user?.access_token;
    }, [auth]);

    return useCallback(async (input : RequestInfo, init ?: RequestInit) : Promise<Response> => {
        if (!accessToken.current) {
            throw new Error('API fetch requires an authenticated user');
        }

        if (!init) {
            init = {};
        }

        init.headers = init.headers instanceof Headers ? init.headers : new Headers(init.headers);
        init.headers.append('Authorization', `Bearer ${accessToken.current}`);
        init.headers.append('Content-Type', 'application/json');
        init.headers.append('Accept', 'application/json');

        return fetch(input, init);
    }, []);
};

export default useApiFetch;
