import './main.scss';
import {WebStorageStateStore} from 'oidc-client-ts';
import {StrictMode} from 'react';
import {render} from 'react-dom';
import {AuthProvider} from 'react-oidc-context';
import App from './App';
import AuthGuard from './components/Auth/AuthGuard';
import AccountProvider from '@/components/Auth/AccountProvider';

render(
    (
        <AuthProvider
            authority={import.meta.env.VITE_APP_OIDC_AUTHORITY}
            client_id={import.meta.env.VITE_APP_OIDC_CLIENT_ID}
            redirect_uri={window.location.origin}
            scope="offline openid"
            userStore={new WebStorageStateStore({store: window.localStorage})}
            onSigninCallback={user => {
                let returnTo = '/';
                const state = user ? user.state as {returnTo ?: string} : {};

                if (state.returnTo) {
                    returnTo = state.returnTo;
                }

                window.history.replaceState({}, document.title, returnTo);
            }}
        >
            <StrictMode>
                <AuthGuard>
                    <AccountProvider>
                        <App/>
                    </AccountProvider>
                </AuthGuard>
            </StrictMode>
        </AuthProvider>

    ),
    document.getElementById('root')
);
