import type {Reducer} from 'react';
import type {Account} from '@/types/account';

type State = Account;

type UpdateNameAction = {
    type : 'update-name';
    payload : {
        firstName : string;
        lastName : string;
    };
};

type UpdateEmailAction = {
    type : 'update-emailAddress';
    payload : {
        emailAddress : string;
    };
};

export type AccountAction = UpdateNameAction | UpdateEmailAction;

export const accountReducer : Reducer<State, AccountAction> = (state, action) => {
    switch (action.type) {
        case 'update-emailAddress':
        case 'update-name':
            return {
                ...state,
                ...action.payload,
            };
    }
};
