import type {Dispatch, ReactNode} from 'react';
import {createContext, useContext} from 'react';
import type {AccountAction} from '@/components/Auth/accountReducer';
import {accountReducer} from '@/components/Auth/accountReducer';
import AuthError from '@/components/Auth/AuthError';
import FullPageLoadingIndicator from '@/components/FullPageLoadingIndicator';
import useApiFetch, {apiUrl} from '@/hooks/useApiFetch';
import type {ApiFetch} from '@/hooks/useApiFetch';
import useAsync from '@/hooks/useAsync';
import {mapToAccount} from '@/mappers/account';
import type {Account, RawAccount} from '@/types/account';

type Props = {
    children ?: ReactNode | undefined;
};

type AccountContext = {
    account : Account;
    dispatch : Dispatch<AccountAction>;
};

const accountContext = createContext<AccountContext | null>(null);

export const useAccount = () : AccountContext => {
    const context = useContext(accountContext);

    if (!context) {
        throw new Error('Context used before initialization');
    }

    return context;
};

const fetchAccount = async (signal : AbortSignal, fetch : ApiFetch) : Promise<Account> => {
    const response = await fetch(apiUrl('/api/account').toString(), {signal});

    if (!response.ok) {
        throw new Error('Unable to fetch account');
    }

    const rawAccount = await response.json() as RawAccount;
    return mapToAccount(rawAccount);
};

const AccountProvider = ({children} : Props) : JSX.Element => {
    const apiFetch = useApiFetch();
    const accountState = useAsync(fetchAccount, [apiFetch], accountReducer);

    if (accountState.error) {
        return (
            <AuthError
                message="Unable to load your account."
                onRetry={accountState.reload}
            />
        );
    }

    if (accountState.loading) {
        return <FullPageLoadingIndicator/>;
    }

    return (
        <accountContext.Provider value={{
            account: accountState.result,
            dispatch: accountState.dispatch,
        }}>
            {children}
        </accountContext.Provider>
    );
};

export default AccountProvider;
