import {lazy, Suspense} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {BrowserRouter} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import ErrorFallback from './ErrorFallback';
import {useAccount} from '@/components/Auth/AccountProvider';
import FullPageLoadingIndicator from '@/components/FullPageLoadingIndicator';
import 'react-toastify/dist/ReactToastify.min.css';

const AdminApp = lazy(async () => import('./apps/admin/AdminApp'));
const OperatorApp = lazy(async () => import('./apps/operator/OperatorApp'));

const App = () : JSX.Element => {
    const {account} = useAccount();

    return (
        <>
            <ToastContainer position="bottom-left"/>
            <BrowserRouter>
                <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                    onReset={() => {
                        window.location.assign(window.origin);
                    }}
                >
                    <Suspense fallback={<FullPageLoadingIndicator message="Loading application"/>}>
                        {account.role === 'storage-operator'
                            ? <OperatorApp/>
                            : <AdminApp/>
                        }
                    </Suspense>
                </ErrorBoundary>
            </BrowserRouter>
        </>
    );
};

export default App;
