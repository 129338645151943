import {Alert, Button, Container} from 'react-bootstrap';
import type {FallbackProps} from 'react-error-boundary';

const ErrorFallback = ({error, resetErrorBoundary} : FallbackProps) : JSX.Element => {
    return (
        <div className="d-flex w-100 vh-100 align-items-center justify-content-center">
            <Container>
                <Alert variant="danger">
                    <Alert.Heading>Looks like something went wrong!</Alert.Heading>
                    <p>
                        We encountered an unrecoverable error. If the problem persists, feel free to contact us. In the
                        meantime, try refreshing.
                    </p>
                    {import.meta.env.DEV && (
                        <>
                            <p>
                                {error.message}
                            </p>
                            {error.stack && (
                                <pre>
                                    {error.stack.split('\n')
                                        .filter(line => !line.includes('node_modules'))
                                        .join('\n')
                                    }
                                </pre>
                            )}
                        </>
                    )}
                    <hr/>
                    <div className="d-flex justify-content-end">
                        <Button onClick={resetErrorBoundary} variant="outline-danger">
                            Refresh
                        </Button>
                    </div>
                </Alert>
            </Container>
        </div>
    );
};

export default ErrorFallback;
